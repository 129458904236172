html {
    min-width: 320px;
}

body {
    height: 100%;
}

#app {
    min-width: 100%;
    min-height: 100vh;
}

.sticky-table {
    overflow: auto;

    thead tr th {
        position: sticky;
        top: 0;
    }

    tbody tr td:first-child,
    thead tr th:first-child {
        position: sticky;
        left: 0;
        @apply border-r border-border;
    }

    tr th:first-child {
        z-index: 12;
    }

    tr td:first-child {
        z-index: 8;
    }
}
