@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,600i,700,700i&display=swap');

/*
Custom
*/
@import '_components/app';
@import '_components/spinner';

@import '~react-responsive-carousel/lib/styles/carousel.min.css';

//Add css variables for shadcn/ui
@layer base {
    :root {
        --background: 0 0% 100%;
        //--foreground: 222.2 47.4% 11.2%;
        --foreground: 0 0% 29%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        //--primary: 222.2 47.4% 11.2%;
        //--primary: 35 100% 54%; // brand orange
        --primary: 166 38% 48%; // brand "blue" (green)
        --primary-foreground: 0 0% 100%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%; // original, gray color
        //--accent: 164 33% 78%; // deisgned light green color
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
        --font-sans: 'PT Sans';
        --font-secondary: 'Montserrat';
    }
}

@layer base {
    * {
        @apply border-border;
    }

    html {
        text-size-adjust: 100%;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    body {
        @apply text-foreground;
        @apply bg-gray-50;
        color: hsl(0, 0%, 29%);
        font-feature-settings:
            'rlig' 1,
            'calt' 1;
    }

    hr {
        @apply my-6 h-[2px];
    }

    a {
        @apply text-blue;
    }
}

//Fixes for upload care widget
.uploadcare--button_primary,
.uploadcare--widget__button {
    background-color: #157cfc;
}

.react-datepicker-input-wrapper,
.react-datepicker-input-wrapper > div.react-datepicker-wrapper,
.react-datepicker-input-wrapper
    > div
    > div.react-datepicker__input-container
    .react-datepicker-input-wrapper
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
}
